import React from "react"
import { Link } from "gatsby"

export default () => (
    <div id="footerMobile">
        <div className="inner">
            <div className="footerMobileContent">
                <div className="footerMobilephone">
                <a href="tel:0661515004">
                    06-6151-5004<br /><span>営業時間 平日 10:00 - 17:30</span>
                </a>
                </div>
                <div className="footerMobileMail"><a href="mailto:kono@kono-office.com">
                    <img src="/images/mail.svg" alt="email" /></a></div>
                <div className="footerMobileButton">
                <Link to={`/contact/`}>問い合わせる</Link>
                </div>
            </div>       
        </div>
    </div>
)
