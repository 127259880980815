import React from "react"
import { Link } from "gatsby"

export default () => (
    <footer>
        <div className="inner">
            <div className="footerList">
                <div>
                    <Link to={`/termsofuse/`}>サイトのご利用にあたって</Link>
                    <Link to={`/privacypolicy/`}>個人情報の取扱い</Link>
                </div>
                <p className="copyright">COPYRIGHT 甲能税理士事務所. ALL RIGHTS RESERVED</p>
            </div>
        </div>
    </footer>
)
