import React from "react"
import { Link } from "gatsby"


const NavbarLinks = () => {
  return (
    <nav className="globalNav">
        <div className="wrapper">
            <ul>
                <li><Link to={`/service/`}>資金調達</Link></li>
                <li  className="ulSecondParent"><Link to={`/shikin-chotatsu/`}>資金調達特集</Link></li>
                <li  className="ulSecond">
                  <ul>
                    <li><Link to={`/yushi/`}><span>- </span>融資</Link></li>
                    <li><Link to={`/hojokin/`}><span>- </span>補助金</Link></li>
                    <li><Link to={`/cloud-funding/`}><span>- </span>クラウドファンディング</Link></li>
                    <li><Link to={`/equity-finance/`}><span>- </span>エクイティ・ファイナンス</Link></li>
                  </ul>
                </li>
                <li><Link to={`/sogyomae/`}>今からできる資金調達</Link></li>
                <li><Link to={`/jirei/`}>事例紹介</Link></li>
                <li><Link to={`/about/`}>ABOUT</Link></li>
                <li><Link to={`/contact/`}>お問い合わせ</Link></li>
            </ul>
        </div>
    </nav>
  )
}

export default NavbarLinks