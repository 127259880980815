import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import FooterMobie from "../components/footerMobile"
import "./layout.css"

export default ( {children} ) => (
  <div>
      <Header />
          {children}
      <FooterMobie />
      <Footer />
  </div>
)
